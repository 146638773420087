import { SerpType } from '@chat/service';
import { AppConfig } from './config';

export class ServiceApi {
  static async search(req: SerpType.search.Request) {
    const res = await fetch(`${AppConfig.config?.BACK}/api/serp/search`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    });
    return res.json() as Promise<SerpType.search.Response>;
  }
}
