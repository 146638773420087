import { FormattedMessage } from 'react-intl';
import Plugin from '../core/plugins';
import { PluginDescription } from '../core/plugins/plugin-description';
import { OpenAIMessage, Parameters } from '../core/chat/types';

const defaultSystemPrompt = `You are a helpful assistant named VLAD, which stands for Valuation Legend At Duty. You work for Copping Joyce Surveyors a firm of RICS surveyors and valuers specialising in property valuations, social housing advisory, development advisory, expert witness and LPA receivership. You must help them with their work-related queries. Make sure you are factual - don't invent anything. If they ask something that is not work-related, jokingly tell them to get on with their day-to-day work but then still answer.`;

export interface SystemPromptPluginOptions {
  systemPrompt: string;
}

export class SystemPromptPlugin extends Plugin<SystemPromptPluginOptions> {
  describe(): PluginDescription {
    return {
      id: 'system-prompt',
      name: '',
      options: [
        {
          id: 'systemPrompt',
          defaultValue: defaultSystemPrompt,
          displayOnSettingsScreen: 'chat',
          displayAsSeparateSection: true,
          resettable: true,
          scope: 'chat',
          renderProps: (value, options, context) => ({
            type: 'textarea',
            label: 'System Prompt',
            description: context.intl.formatMessage({
              defaultMessage:
                'The System Prompt is an invisible message inserted at the start of the chat and can be used to give ChatGPT information about itself and general guidelines for how it should respond.',
            }),
          }),
          displayInQuickSettings: {
            name: 'System Prompt',
            displayByDefault: true,
            label: 'Customize system prompt',
          },
        },
      ],
    };
  }

  async preprocessModelInput(
    messages: OpenAIMessage[],
    parameters: Parameters,
  ): Promise<{ messages: OpenAIMessage[]; parameters: Parameters }> {
    const output = [
      {
        role: 'system',
        content: (this.options?.systemPrompt || defaultSystemPrompt).replace(
          '{{ datetime }}',
          new Date().toLocaleString(),
        ),
      },
      ...messages,
    ];

    return {
      messages: output,
      parameters,
    };
  }
}
