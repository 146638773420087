import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

const initialState = {
  show: false as boolean,
};

export const memoryUISlice = createSlice({
  name: 'memory',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export const { setOpen } = memoryUISlice.actions;

export const closeMemoryUI = () => memoryUISlice.actions.setOpen(false);
export const openMemoryUI = () => memoryUISlice.actions.setOpen(true);
export const selectMemoryOptions = (state: RootState) => state.memoryUI.show;

export default memoryUISlice.reducer;
