import React from 'react';
import { LoginModal } from './auth-modals';
import { useAuth } from '../core/authContext';
import { Navigate } from 'react-router-dom';
import { Loader } from '@mantine/core';

export default function LoginPage(props: any) {
  const authContext = useAuth();

  if (authContext.isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader color="#B8860B" variant="dots" />
      </div>
    );
  }

  if (authContext.user) {
    return <Navigate to={'/'} />;
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <LoginModal />
    </div>
  );
}
