import Plugin from '../core/plugins';
import { PluginDescription } from '../core/plugins/plugin-description';
import { OpenAIMessage, Parameters } from '../core/chat/types';
import { runChatTrimmer } from '../core/tokenizer/wrapper';
import { createChatCompletion, defaultModel } from '../core/chat/openai';
import { v4 as uuidv4 } from 'uuid';
import markdownToTxt from 'markdown-to-txt';
import {
  clearParameters,
  loadParameters,
  saveParameters,
} from '../core/chat/parameters';

import { AppConfig } from '../core/config';

const userPrompt = (messages: OpenAIMessage[]) => {
  return (
    messages
      .map((m) => `${m.role.toLocaleUpperCase()}:\n${m.content}`)
      .join('\n===\n') + '\n===\nTitle:'
  );
};

interface MemoryItem {
  uuid: string;
  timestamp: number;
  chatId?: string;
  value?: string;

  chatUpdatedTimestamp?: number;
  chatUpdated?: OpenAIMessage[];
}

export interface MemoryPluginOptions {
  list: MemoryItem[];
  prompt: string;
}

export class MemoryPlugin extends Plugin<MemoryPluginOptions> {
  static mapUpdater = new Map<string, ReturnType<typeof setTimeout>>();

  describe(): PluginDescription {
    return {
      id: 'memory',
      name: 'Memory generator',
      hidden: false,
      options: [
        {
          id: 'list',
          scope: 'user',
          displayOnSettingsScreen: 'memory',
          displayAsSeparateSection: true,
          defaultValue: [
            {
              uuid: 'time',
              timestamp: Date.now(),
              value:
                'If user prompt requires up-to-date or date dependent data, use the serpApiSearch function to get the current date.',
            },
          ],
          renderProps: {
            type: 'none',
          },
        },
        {
          id: 'prompt',
          scope: 'user',
          displayOnSettingsScreen: 'memory',
          displayAsSeparateSection: true,
          defaultValue: '',
          renderProps: (value, options, context) => ({
            type: 'textarea',
            label: 'Memory prompt',
            description: context.intl.formatMessage({
              defaultMessage: 'This value or config.json(MEMORY_PROMPT)',
            }),
          }),
        },
      ],
    };
  }

  async initialize() {
    console.log('initialize:', this.options);
    this.context?.setOptions('list', (old = []) =>
      old.filter((e) => (Date.now() - e.timestamp) / 1000 / 60 / 60 / 24 < 30),
    );
    await Promise.all(
      this.options?.list
        .filter((i) => i.chatUpdatedTimestamp)
        .map((i) => {
          if (!i.chatUpdatedTimestamp) return i;
          console.log(
            `Waiting memory update(${i.chatId!}): ${i.chatUpdatedTimestamp - Date.now()}ms`,
          );
          MemoryPlugin.mapUpdater.set(
            i.chatId!,
            setTimeout(
              () => this.generate(i.chatId!).catch((e) => console.error(e)),
              i.chatUpdatedTimestamp - Date.now(),
            ),
          );
        }) || [],
    );
  }

  async actions(): Promise<void> {
    for (const key of MemoryPlugin.mapUpdater.keys()) {
      clearTimeout(MemoryPlugin.mapUpdater.get(key)!);
      MemoryPlugin.mapUpdater.delete(key);
      this.generate(key).catch((e) => console.error(e));
    }
  }

  async generate(chatId: string) {
    const timerId = MemoryPlugin.mapUpdater.get(chatId);
    if (timerId) clearTimeout(timerId);
    const item = this.options?.list.find((m) => m.chatId === chatId);
    console.log('generate:', { chatId, ...item });
    if (!item) return;
    if (!item.chatUpdated) return;

    let messages = item.chatUpdated.filter(
      (m) => m.role === 'user' || m.role === 'assistant',
    );

    // messages = await runChatTrimmer(messages, {
    //   maxTokens: 1024,
    //   preserveFirstUserMessage: true,
    //   preserveSystemPrompt: false,
    // });

    messages = messages.filter(Boolean);

    messages = [
      {
        role: 'system',
        content: 'You are an instructions helper assistant.',
      },

      ...messages.map((m) => ({
        role: m.role,
        content: m?.content,
      })),
      {
        role: 'user',
        content:
          this.context?.getOptions().prompt ||
          AppConfig.config?.MEMORY_PROMPT ||
          '',
      },
    ];

    const output = await createChatCompletion(messages, loadParameters(chatId));
    clearParameters(chatId);

    this.context?.setOptions('list', (old = []) => {
      const item = old.find((m) => m.chatId === chatId);
      if (!item) return old;
      delete item.chatUpdated;
      delete item.chatUpdatedTimestamp;
      MemoryPlugin.mapUpdater.delete(chatId);
      item.value = markdownToTxt(output).replaceAll('||', '\n');
      return [...old.filter((m) => m.chatId !== chatId), item];
    });
  }

  async postprocessModelOutput(
    message: OpenAIMessage,
    contextMessages: OpenAIMessage[],
    parameters: Parameters,
    done: boolean,
  ): Promise<OpenAIMessage> {
    if (!done) return message;
    const chatId = this.context?.getCurrentChat().id!;

    this.context?.setOptions('list', (old = []) => {
      const timerId = MemoryPlugin.mapUpdater.get(chatId);
      if (timerId) clearTimeout(timerId);
      const newState =
        old.find((m) => m.chatId === chatId) ||
        ({ uuid: uuidv4(), timestamp: Date.now(), chatId } as MemoryItem);

      newState.chatUpdatedTimestamp = Date.now() + 60_000;
      newState.chatUpdated = [
        ...contextMessages.filter(
          (m) => m.role === 'user' || m.role === 'assistant',
        ),
        message,
      ];

      saveParameters(chatId, {
        ...parameters,
        model: defaultModel,
        temperature: 0,
      });

      console.log(
        `Waiting memory update(${chatId}): ${newState.chatUpdatedTimestamp - Date.now()}ms`,
      );
      MemoryPlugin.mapUpdater.set(
        chatId!,
        setTimeout(
          () => this.generate(chatId).catch((e) => console.error(e)),
          newState.chatUpdatedTimestamp - Date.now(),
        ),
      );

      return [...old.filter((m) => m.chatId !== chatId), newState];
    });

    return message;
  }
}
