import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IntlShape, useIntl } from 'react-intl';
import { Backend, User } from './backend';
import { ChatManager } from './';
import { useAppDispatch } from '../store';
import { openOpenAIApiKeyPanel } from '../store/settings-ui';
import { Attachment, Message, Parameters } from './chat/types';
import { useChat, UseChatResult } from './chat/use-chat';
import { TTSContextProvider } from './tts/use-tts';
import { useLocation, useParams } from 'react-router-dom';
import { isProxySupported } from './chat/openai';
import { resetAudioContext } from './tts/audio-file-player';
import { ChatModel } from 'openai/resources';
import { AppConfig, loadAppConfig } from './config';

export interface Context {
  authenticated: boolean;
  sessionExpired: boolean;
  chat: ChatManager;
  intl: IntlShape;
  id: string | undefined | null;
  currentChat: UseChatResult;
  isHome: boolean;
  isShare: boolean;
  generating: boolean;
  deleteChat: (chatID: string) => Promise<void>;
  onNewMessage: (
    message?: string,
    attachments?: Attachment[],
  ) => Promise<string | false>;
  regenerateMessage: (message: Message) => Promise<boolean>;
  editMessage: (
    message: Message,
    content: string,
    attachments: Attachment[],
  ) => Promise<boolean>;
  user: User | null;
  setUser: React.Dispatch<User | null>;
  appConfig?: AppConfig;
  memoryRunAll: () => Promise<false | undefined>;
}

const AppContext = React.createContext<Context>({} as any);

const chatManager = new ChatManager();
const backend = new Backend(chatManager);

let intl: IntlShape;

// TODO: Change all instances to getting from local storage;

export function useCreateAppContext(): Context {
  const { id: _id } = useParams();
  const [appConfig, setAppConfig] = useState<AppConfig | undefined>();
  const [nextID, setNextID] = useState(uuidv4());
  const id = _id ?? nextID;

  const dispatch = useAppDispatch();

  intl = useIntl();

  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const isShare = pathname.startsWith('/s/');

  const currentChat = useChat(chatManager, id, isShare);
  const [authenticated, setAuthenticated] = useState(
    backend?.isAuthenticated || false,
  );
  const [wasAuthenticated, setWasAuthenticated] = useState(
    backend?.isAuthenticated || false,
  );
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fn = (update) => backend?.receiveYUpdate(update);
    chatManager.on('y-update', fn);
    return () => {
      chatManager.removeListener('y-update', fn);
    };
  }, []);

  const updateAuth = useCallback((authenticated: boolean) => {
    setAuthenticated(authenticated);
    if (authenticated && backend.user) {
      chatManager.login(backend.user.email || backend.user.id);
    }
    if (authenticated) {
      setWasAuthenticated(true);
      localStorage.setItem('registered', 'true');
    }
  }, []);

  useEffect(() => {
    updateAuth(backend?.isAuthenticated || false);
    backend?.on('authenticated', updateAuth);
    return () => {
      backend?.off('authenticated', updateAuth);
    };
  }, [updateAuth]);

  useEffect(() => {
    loadAppConfig()
      .then((c) => setAppConfig(c))
      .catch((e) => console.log(e));
  }, [loadAppConfig]);

  const deleteChat = useCallback(
    async (chatID: string) => {
      if (isShare) return;

      const apiKey = localStorage.getItem('openai-api-key')!;
      if (!apiKey && !isProxySupported()) return;

      await chatManager.deleteChat(chatID, apiKey);
      await backend?.deleteChat(chatID);
    },
    [dispatch, id, currentChat.leaf, isShare],
  );

  const onNewMessage = useCallback(
    async (message?: string, attachments?: Attachment[]) => {
      resetAudioContext();

      if (isShare) return false;
      if (!message?.trim().length) return false;
      if (isProxySupported() && attachments) return false;

      const apiKey = localStorage.getItem('openai-api-key')!;
      if (!apiKey && !isProxySupported()) return false;

      const parameters: Parameters = {
        model: chatManager.options.getOption<ChatModel>(
          'parameters',
          'model',
          id,
          false,
          AppConfig.config?.ONLY_DEFAULT_MODEL,
        ),
        temperature: chatManager.options.getOption<number>(
          'parameters',
          'temperature',
          id,
        ),
      };

      if (id === nextID) {
        setNextID(uuidv4());

        const autoPlay = chatManager.options.getOption<boolean>(
          'tts',
          'autoplay',
        );

        if (autoPlay) {
          const ttsService = chatManager.options.getOption<string>(
            'tts',
            'service',
          );
          if (ttsService === 'web-speech') {
            const utterance = new SpeechSynthesisUtterance('Generating');
            utterance.volume = 0;
            speechSynthesis.speak(utterance);
          }
        }
      }

      const param = {
        chatID: id,
        content: message.trim(),
        requestedParameters: {
          ...parameters,
          apiKey,
        },
        attachments,
      };

      if (chatManager.has(id)) {
        chatManager.sendMessage({
          ...param,
          parentID: currentChat.leaf?.id,
        });
      } else {
        await chatManager.createChat(id);
        chatManager.sendMessage({
          ...param,
          parentID: currentChat.leaf?.id,
        });
      }

      return id;
    },
    [dispatch, id, currentChat.leaf, isShare],
  );

  const regenerateMessage = useCallback(
    async (message: Message) => {
      resetAudioContext();

      if (isShare) {
        return false;
      }

      // const openaiApiKey = store.getState().apiKeys.openAIApiKey;
      const apiKey = localStorage.getItem('openai-api-key')!;

      if (!apiKey && !isProxySupported()) {
        dispatch(openOpenAIApiKeyPanel());
        return false;
      }

      const parameters: Parameters = {
        model: chatManager.options.getOption<ChatModel>(
          'parameters',
          'model',
          id,
          false,
          AppConfig.config?.ONLY_DEFAULT_MODEL,
        ),
        temperature: chatManager.options.getOption<number>(
          'parameters',
          'temperature',
          id,
        ),
      };

      await chatManager.regenerate(message, {
        ...parameters,
        apiKey,
      });

      return true;
    },
    [dispatch, isShare],
  );

  const editMessage = useCallback(
    async (message: Message, content: string, attachments: Attachment[]) => {
      resetAudioContext();
      if (isShare) return false;
      if (!content?.trim().length) return false;

      // const openaiApiKey = store.getState().apiKeys.openAIApiKey;
      const apiKey = localStorage.getItem('openai-api-key')!;

      if (!apiKey && !isProxySupported()) {
        dispatch(openOpenAIApiKeyPanel());
        return false;
      }

      const parameters: Parameters = {
        model: chatManager.options.getOption<ChatModel>(
          'parameters',
          'model',
          id,
          false,
          AppConfig.config?.ONLY_DEFAULT_MODEL,
        ),
        temperature: chatManager.options.getOption<number>(
          'parameters',
          'temperature',
          id,
        ),
      };

      await chatManager.sendMessage({
        chatID: id,
        content: content.trim(),
        requestedParameters: {
          ...parameters,
          apiKey,
        },
        attachments,
        parentID: message.parentID,
        editMessageId: message.id,
      });

      return true;
    },
    [dispatch, id, isShare],
  );

  const memoryRunAll = useCallback(async () => {
    if (isShare) return false;
    await chatManager.pluginActions();
  }, [isShare]);

  const generating =
    currentChat?.messagesToDisplay?.length > 0
      ? !currentChat.messagesToDisplay[currentChat.messagesToDisplay.length - 1]
          .done
      : false;

  const context = useMemo<Context>(
    () => ({
      authenticated,
      sessionExpired: !authenticated && wasAuthenticated,
      id,
      intl,
      chat: chatManager,
      currentChat,
      isHome,
      isShare,
      generating,
      onNewMessage,
      regenerateMessage,
      editMessage,
      setUser,
      user,
      deleteChat,
      appConfig,
      memoryRunAll,
    }),
    [
      authenticated,
      wasAuthenticated,
      generating,
      onNewMessage,
      regenerateMessage,
      editMessage,
      currentChat,
      id,
      isHome,
      isShare,
      intl,
      deleteChat,
      appConfig,
      memoryRunAll,
    ],
  );

  return context;
}

export function useAppContext() {
  return React.useContext(AppContext);
}

export function AppContextProvider(props: { children: React.ReactNode }) {
  const context = useCreateAppContext();
  return (
    <AppContext.Provider value={context}>
      <TTSContextProvider>{props.children}</TTSContextProvider>
    </AppContext.Provider>
  );
}
