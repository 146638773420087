import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth, usersRef } from '../global-options/firebase-config';
import { getDocs } from 'firebase/firestore';

interface Auth {
  user: null | User;
  key: string;
  isLoading: boolean;
  roles: string[];
  error: string | null;
  setError: (s: string) => void;
  setKey: (s: string) => void;
  setIsLoading: (s: boolean) => void;
}

const AuthContext = createContext<Auth>({
  user: null,
  key: '',
  isLoading: true,
  roles: [],
  error: null,
  setError: () => {},
  setKey: () => {},
  setIsLoading: () => {},
});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [key, setKey] = useState('');

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      if (currentUser === null) {
        setIsLoading(false);
      }

      if (currentUser) {
        getDocs(usersRef)
          .then((snapshot) => {
            let users: any[] = [];
            snapshot.docs.forEach((doc) => {
              users.push({ ...doc.data(), id: doc.id });
            });

            let foundUser = users.find((item) => item.id === currentUser.email);
            const devAddRoles = JSON.parse(
              localStorage.getItem('dev-add-roles') || '[]',
            );

            if (foundUser) {
              setRoles([...foundUser.roles, ...devAddRoles]);
              if (foundUser.openAIKey) {
                setKey(foundUser.openAIKey);
                localStorage.setItem('openai-api-key', foundUser.openAIKey);
              } else {
                localStorage.removeItem('openai-api-key');
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      (window as any).setRoles = (role = ['dev', 'admin']) => {
        localStorage.setItem('dev-add-roles', JSON.stringify(role));
        setRoles(role);
      };
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        roles,
        error,
        setError,
        key,
        setKey,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
