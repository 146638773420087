import { ChatModel } from 'openai/resources';
import { MessageTree } from './message-tree';
import OpenAI from 'openai';

export interface AiContext {
  threadId?: string;
  assistantId?: string;
  storeId?: string;
  eventFile?: { [fileName: string]: 'del' | 'up' };
  fileHash?: string;
  instructionsHash?: string;
}

export interface Parameters {
  temperature: number;
  apiKey?: string;
  initialSystemPrompt?: string;
  model: ChatModel;
  context?: AiContext;
}

export class FileUploaded {
  uuid!: string;
  name!: string;
  data!: OpenAI.Beta.Threads.ThreadCreateParams.Message.Attachment;
  storFileDelete?: boolean;

  static is(val: Attachment): val is FileUploaded {
    return 'data' in val;
  }
}

export class FileSource {
  uuid!: string;
  source!: File;

  static is(val: Attachment): val is FileSource {
    return 'source' in val;
  }
}

export type Attachment = FileUploaded | FileSource;

export interface Message {
  id: string;
  chatID: string;
  parentID?: string;
  timestamp: number;
  role: string;
  model?: ChatModel;
  content: string;
  parameters?: Parameters;
  done?: boolean;
  init?: boolean;
  serp?: 'done' | 'loaded';
  attachments?: Attachment[];
}

export interface UserSubmittedMessage {
  chatID: string;
  parentID?: string;
  content: string;
  requestedParameters: Parameters;
  attachments?: Attachment[];
  editMessageId?: string;
}

export interface OpenAIMessage {
  role: string;
  content: string;
  attachments?: OpenAI.Beta.Threads.ThreadCreateParams.Message.Attachment[];
}

export interface Chat {
  id: string;
  messages: MessageTree;
  metadata?: Record<string, any>;
  pluginOptions?: Record<string, any>;
  title?: string | null;
  created: number;
  updated: number;
  deleted?: boolean;
}

export function serializeChat(chat: Chat): string {
  return JSON.stringify({
    ...chat,
    messages: chat.messages.serialize(),
  });
}

export function deserializeChat(serialized: string) {
  const chat = JSON.parse(serialized);
  chat.messages = new MessageTree(chat.messages);
  return chat as Chat;
}
